/*
	Constants
*/
#root {
	$primary: #d7d7d7;
	$deep-primary: #c1c1c1;
	$invert-primary: #424242;
	$secondary: #e74c3c;
}

/*
	General Tags and Classes
*/

@font-face {
	font-family: Sen;
	src: url(./Sen-Regular.otf) format("opentype");
}

body {
	background-color: var(--primary);
	font-family: Sen;
	margin: 0;
	padding: 0;
}

a {
	color: var(--secondary);
	text-decoration: none;
}

h1 {
	color: var(--invert-primary);
	font-size: 2.5rem;
}

h2 {
	color: var(--invert-primary);
	font-size: 1.75rem;
}

.header-light {
	color: var(--primary);
}

.App {
	text-align: center;
	position: relative;
}

.page {
	position: absolute;
	left: 0;
	right: 0;
}

.container {
	text-align: center;
	background: var(--primary);
	border: 1px solid var(--secondary);
	opacity: 0.8;
	padding: 1rem 1rem;
	margin: 4rem 2rem;
	border-radius: 2rem;

	p {
		font-size: 1.2rem;
	}
}

.secondary-color {
	color: var(--secondary);
	margin: 0;
}

.responsive {
	width: 100%;
	max-width: 400px;
	height: auto;
}

/* 
	Component Transitions
*/
.fade-appear,
.fade-enter {
	opacity: 0;
	z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
	opacity: 1;
	transition: opacity 300ms linear 150ms;
}

.fade-exit {
	opacity: 1;
}

.fade-exit.fade-exit-active {
	opacity: 0;
	transition: opacity 150ms linear;
}

/*
	Buttons
*/

.button-container {
	height: 100%;
	display: flex;
	justify-content: center;
}

.btn {
	text-decoration: none;
	padding: 20px 50px;
	font-size: 1.25rem;
	position: relative;
	margin: 32px;
}

// button 1
.btn-1 {
	background: var(--invert-primary);
	color: var(--primary);
	border-radius: 30px;
	transition: transform 0.3s ease;
}

.btn-1::after,
.btn-1::before {
	content: "";
	position: absolute;
	opacity: 0.3;
	background: var(--invert-primary);
	border-radius: inherit;
	width: 100%;
	height: 100%;
	left: 0;
	bottom: 0;
	z-index: -1;
	transition: transform 0.3s ease;
}

.btn-1:hover {
	transform: translate(-12px, -12px);
}

.btn-1:hover::after {
	transform: translate(6px, 6px);
}

.btn-1:hover::before {
	transform: translate(12px, 12px);
}

// Button 2

.btn-2 {
	color: var(--secondary);
}

.btn-2::after,
.btn-2::before {
	border: 3px solid var(--secondary);
	content: "";
	position: absolute;
	width: calc(100% - 6px);
	height: calc(100% - 6px);
	left: 0;
	bottom: 0;
	z-index: -1;
	transition: transform 0.3s ease;
}

.btn-2:hover::after {
	transform: translate(-5px, -5px);
}

.btn-2:hover::before {
	transform: translate(5px, 5px);
}

@function hex($hexcolor, $opacity) {
	$red: red($hexcolor);
	$green: green($hexcolor);
	$blue: blue($hexcolor);
	$alpha: alpha($hexcolor);

	@if $opacity ==1 or $opacity ==100% {
		@return unquote("rgba(#{$red},#{$green},#{$blue},#{$alpha})");
	}

	@else {
		@return unquote("rgba(#{$red},#{$green},#{$blue},#{$opacity})");
	}
}

// Button 3
.btn-3 {
	overflow: hidden;
	color: #fff;
	border-radius: 30px;
	box-shadow: 0 0 0 0 var(--secondary), 0 0 0 0 var(--secondary);
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.btn-3::after {
	content: "";
	width: 400px;
	height: 400px;
	position: absolute;
	top: -50px;
	left: -100px;
	background-color: var(--secondary);
	background-image: linear-gradient(225deg,
			#02180a 0%,
			var(--secondary) 50%,
			var(--secondary) 100%);
	z-index: -1;
	transition: transform 0.75s ease;
}

.btn-3:hover {
	transform: translate(0, -6px);
	box-shadow: 0 0 0 0 rgba(248, 64, 64, 0.5), 0 0 0 0 rgba(255, 33, 33, 0.5);
}

.btn-3:hover::after {
	transform: rotate(150deg);
}

/*
	Navbars
*/

.nav {
	margin: 0;
	padding: 1.5em;

	li {
		list-style: none;

		a {
			font-size: 1.5em;
			padding: 1em;
			color: var(--invert-primary);
			text-decoration: none;
			-webkit-transition: background-color 0.5s;
			-o-transition: background-color 0.5s;
			transition: background-color 0.5s, color 0.5s;

			&:hover {
				background-color: var(--secondary);
				color: var(--deep-primary);

				span {
					color: var(--deep-primary);
				}
			}
		}
	}
}

.table-contents {
	margin: 0;
	padding: 1.75em;

	li {
		list-style: none;

		a {
			font-size: 1.5em;
			padding: 1em;
			color: var(--invert-primary);
			text-decoration: none;
			-webkit-transition: background-color 0.5s;
			-o-transition: background-color 0.5s;
			transition: background-color 0.5s, color 0.5s;

			&:hover {
				background-color: var(--secondary);
				color: var(--deep-primary);

				span {
					color: var(--deep-primary);
				}
			}
		}
	}
}

.nav-bg-light {
	background: var(--primary);
	/* fallback for old browsers */
	background: -webkit-linear-gradient(to right,
			#eaeaea,
			#dbdbdb,
			#f2f2f2,
			#ada996);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right,
			#eaeaea,
			#dbdbdb,
			#f2f2f2,
			#ada996);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.nav-bg-dark {
	background: var(--primary);
	/* fallback for old browsers */
	background: -webkit-linear-gradient(to left,
			#414345,
			#232526);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to left,
			#414345,
			#232526);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;

	.slam-left {
		margin-right: auto;
	}
}

.table-contents {
	display: -webkit-box;
	display: -ms-flexbox;
	display: inline-flex;

	.slam-left {
		margin-right: auto;
	}
}

.footer {
	background-color: #424242;
	color: #d7d7d7;
	position: fixed;
	z-index: 5;
	bottom: 0;
	width: 100%;
}

#copyright {
	margin: 0 0 1% 0;
	color: #d7d7d7;
}

.socials {
	font-size: 1.75rem;
	align-items: center;
	padding: 0;

	path {
		margin: 0;
		padding: 0;
		text-decoration: none;
		-webkit-transition: background-color 0.5s;
		-o-transition: background-color 0.5s;
		transition: background-color 0.5s, color 0.5s;

		&:hover {
			background-color: var(--secondary);
			color: #c1c1c1;

			span {
				color: #c1c1c1;
			}
		}
	}
}

/* 
	Home.js
*/

#main-container {
	margin-bottom: 5%;
}

.main-icons {
	a {
		font-size: 4rem;
		color: var(--invert-primary);
	}

	path {
		text-decoration: none;
		-webkit-transition: background-color 0.5s;
		-o-transition: background-color 0.5s;
		transition: background-color 0.5s, color 0.5s;

		&:hover {
			color: var(--secondary);
		}
	}
}

.profile-pic {
	border: 1px solid var(--secondary);
	padding: 3px;
	border-radius: 100%;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	margin: 0 auto;
	overflow: hidden;
}

.cert-image {
	padding: 3%;
}

/*
	About.js
*/

#page {
	box-sizing: border-box;
}

.side-profile {
	float: right;
	background-color: var(--secondary);
	color: var(--primary);
	border-radius: 10%;
	padding-bottom: 3%;
	height: 105vh;
	margin-right: 1em;
	position: relative;
	z-index: 4;
}

.side-pic {
	width: 100%;
	max-width: 240px;
	height: auto;
	padding: 3px;
	border-radius: 100%;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	margin: 0 auto;
	overflow: hidden;
}

#side-profile-header {
	color: var(--primary);
}

#about-container {
	color: var(--invert-primary);
	text-align: left;
	max-width: 85%;
	height: 105vh;
	z-index: 3;
}

#portfolio-btn {
	height: auto;
}

/*
	Summary.js
*/
#summary-header {
	text-align: left;
}


select,
option {
	display: block;
	margin: 0 auto;
	padding: 0.3rem 0.3rem;
	user-select: none;
	border: none;
	color: var(--invert-primary);
	background: var(--deep-primary);
	border-radius: 0.3rem;
	font-size: 1.2em;
	text-transform: capitalize;
}

.skills-wrap {
	box-sizing: border-box;
	padding: 1em;
	text-align: center;
	width: auto;
	margin: 0 auto;
	border: 0.44rem double rgba(var(--invert-primary), 0.2);

	li {
		box-sizing: border-box;
		display: inline-block;
		font-size: 0em;
		transition: font-size 0.3s linear;
		color: var(--secondary);
	}

	.active {
		color: var(--secondary);
		padding: 1rem;
		font-size: 2.1em;
	}
}

.section-tag {
	text-align: center;
	margin: 0 auto;
	font-size: 1.7em;
	padding-bottom: 1rem;

	span {
		color: rgba(var(--invert-primary), 0.75);
		font-size: 1.8rem;
		padding: 0 0.5rem;
		border-bottom: 0.25rem solid var(--secondary);
		font-style: italic;
	}
}

.flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.job {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	border-bottom: 1px solid var(--secondary);
	margin-bottom: 2rem;
	color: var(--invert-primary);

	p {
		margin-bottom: 1.5rem;
		font-size: 1.3rem;
	}
}

.job:last-of-type {
	border-bottom: none;
	padding-bottom: 0;
}

.job-title {
	text-align: center;
	padding-bottom: 2rem;
}

.job-company .dates {
	font-size: 1.3rem;
}

.job-description {
	text-align: center;

	h3 {
		font-size: 1.3rem;
	}

	font-weight: 800;
}

.job-qualifications {
	margin: 0 0 1.5rem 0;
	text-align: center;
	list-style-position: inside;
	list-style: none;

	/* Remove default bullets */
	li {
		margin: 0 0 0.5rem 0;
		font-size: 1.1rem;
	}

	li::before {
		content: "\2022";
		/* Add content: \2022 is the CSS Code/unicode for a bullet */
		color: var(--secondary);
		/* Change the color */
		font-weight: bold;
		/* If you want it to be bold */
		display: inline-block;
		/* Needed to add space between the bullet and the text */
		width: 1em;
		/* Also needed for space (tweak if needed) */
		margin-left: -1em;
		/* Also needed for space (tweak if needed) */
	}
}

.certlist {
	text-align: center;
	max-width: 1170px;
	margin: 5rem auto;
	display: grid;
	gap: 3rem;
}

@media screen and (min-width: 768px) {
	.certlist {
		grid-template-columns: repeat(3, 1fr);
		/* align-items: start; */
	}

	.cert {
		img {
			max-width: 400px;
		}
	}
}

.cert {
	background: var(--deep-primary);
	border-radius: 1rem;
	padding: 1rem 2rem;
	height: 100%;

	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;

	img {
		max-width: 250px;
	}

	h2 {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	h3 {
		color: var(--invert-primary);
	}

	p {
		margin-top: 0.5rem;
	}

	&:hover {
		-webkit-transform: translateY(-1px);
		transform: translateY(-1px);
		-webkit-box-shadow: 0px 6px 25px var(--secondary);
		box-shadow: 0px 6px 25px var(--secondary);
	}
}

/*
	Mobile/Small Screens
*/
@media all and (max-height: 700px) {

	#about-container {
		max-width: 100%;
		height: 100%
	}

	.side-profile {
		p:last-of-type {
			padding-bottom: 3%;
		}

		height: 100%;
		float: none;
		width: 100%;
		border-radius: 10%;
		padding: 0%;
		margin: 0;
	}
}

@media all and (max-width: 1400px) {
	// #about-container {
	// 	width: 75%;
	// }

	// .side-profile{
	// 	height: 120vh;
	// }

	.certlist {
		margin: 3rem 7rem;
		grid-template-columns: repeat(2, 1fr);
	}
}

@media all and (max-width: 1000px) {
	#about-container {
		text-align: center;
		max-width: 100%;
	}

	#summary-header {
		text-align: center;
	}


	ul {
		padding: 0;

		li {
			width: 100%;

			a {
				display: block;
				text-align: center;
			}
		}
	}

	.nav {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;

		.slam-left {
			margin-right: 0;
		}
	}

	.table-contents {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;

		.slam-left {
			margin-right: 0;
		}
	}

	.side-profile {
		p:last-of-type {
			padding-bottom: 3%;
		}

		height: 100%;
		float: none;
		width: 100%;
		border-radius: 10%;
		padding: 0%;
		margin: 2rem 0rem
	}

	.job {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		padding: 2rem 0;
	}

	.job-title {
		text-align: center;
		-webkit-box-flex: 0;
		-ms-flex: 0 1 200px;
		flex: 0 1 200px;
	}

	.job-description {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.job-company {
		text-align: center;
	}

	.job-description h3 {
		text-align: center;
	}

	.certlist {
		width: 100%;
		margin: 0 0 1rem 0;
		display: grid;
		gap: 3rem;
		grid-template-columns: repeat(1, 1fr);
		padding-bottom: 3%;
	}

	.skills-wrap {
		li {
			width: auto;
		}

		.active {
			padding: 0.2rem;
			margin: 0;
			width: 100%;
		}
	}
}

@media all and (max-width: 410px) {

	select,
	option {
		font-size: 0.8em;
	}

	h1 {
		font-size: 1.8rem;
	}

	h2 {
		font-size: 1.25rem;
	}

	.cert {
		width: 70%;

		img {
			max-width: 100px;
		}
	}

	.skills-wrap {
		li {
			margin: 0;
			padding: 0;
			width: auto;
		}

		.active {
			padding: 0.2rem;
			margin: 0;
		}
	}

	.section-tag {
		span {
			font-size: 1.3rem;
		}
	}
}

@media all and (max-width: 300px) {
	.job-description {
		h3 {
			font-size: 0.9rem;
		}

		p {
			font-size: 0.9rem;
		}
	}

	.btn {
		font-size: 0.9rem;
	}

	h1 {
		font-size: 1.5rem;
	}

	.cert {
		width: 40%;
	}

	h2 {
		font-size: 0.8rem;
	}

	.skills-wrap {
		.active {
			font-size: 1rem;
		}
	}

	.cert {
		img {
			max-width: 50px;
		}
	}
}